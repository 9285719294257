import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasError && prevState.hasError !== this.state.hasError) {
      // Reload the page to the 'Under Construction' URL
      window.location.href = '/under-construction';
    }
  }

  render() {
    if (this.state.hasError) {
      return null; // Optionally render something while redirecting
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
