import React, { useState } from 'react';
import { TextField, Button, Container, Paper, Grid, Typography, Snackbar, Alert, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FaRegEnvelope } from "react-icons/fa";

// Theme definition
const theme = createTheme({
  palette: {
    primary: {
      main: "#000000"
    },
  }
});

// Define form container styles with responsive design
const formContainerStyles = {
  padding: (theme) => theme.spacing(3),
  background: 'rgba(255, 255, 255, 0.8)',
  borderRadius: (theme) => theme.spacing(1),
  marginBottom: (theme) => theme.spacing(3),
  maxWidth: '100%', // Ensure it takes full width of the parent container
  overflow: 'hidden',
};

const formStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: (theme) => theme.spacing(2),
  width: '100%', // Ensure the form takes full width
};

const buttonStyles = {
  marginTop: (theme) => theme.spacing(2),
  width: '100%', // Ensure button takes full width
};

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    organization: '',
    HowCanWeHelpYou: '',
  });

  const [feedback, setFeedback] = useState({
    message: '',
    type: '', 
  });

  const [language, setLanguage] = useState('en'); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://edutech-backend-471f7cb69116.herokuapp.com/api/reachus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        setFeedback({
          message: translations[language].successMessage,
          type: 'success',
        });
      } else {
        setFeedback({
          message: translations[language].errorMessage,
          type: 'error',
        });
      }
    } catch (error) {
      setFeedback({
        message: translations[language].errorMessage,
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
      setOpenSnackbar(true);
    }
  };

  const translations = {
    en: {
      registrationForm: "Get in Touch ",
      firstNameLabel: "First Name",
      lastNameLabel: "Last Name",
      emailLabel: "Email",
      mobileLabel: "Mobile",
      organization: "Organization",
      submitButtonLabel: "Submit",
      successMessage: "Thank you for getting in touch, we will reach you soon!",
      errorMessage: "An error occurred while processing the form.",
      phoneMessage: "Phone number must be exactly 11 digits.",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Paper elevation={3} sx={formContainerStyles}>
          <Typography variant="h4" gutterBottom style={{ color: "#000", textAlign: "center", textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)" }}>
            {translations[language].registrationForm}
          </Typography>
          <form onSubmit={handleSubmit} sx={formStyles}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={translations[language].firstNameLabel}
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={translations[language].lastNameLabel}
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={translations[language].mobileLabel}
                  name="mobile"
                  type="tel"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={translations[language].emailLabel}
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={translations[language].organization}
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="How Can We Help You?"
                  name="HowCanWeHelpYou"
                  value={formData.HowCanWeHelpYou}
                  onChange={handleChange}
                  required
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={buttonStyles}
              disabled={isSubmitting}
              endIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {translations[language].submitButtonLabel}
            </Button>
          </form>
        </Paper>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
          <Alert onClose={() => setOpenSnackbar(false)} severity={feedback.type} sx={{ width: '100%' }}>
            {feedback.message}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
};

export default RegistrationForm;
