import React, { useState, useEffect } from 'react';
import './AgendaSection.css';
import axios from 'axios';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import { FaDownload, FaWhatsapp } from "react-icons/fa";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/system';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  margin: '10px',
  borderRadius: '25px', // Reduced the border radius for a more professional look
  padding: '8px 16px', // Adjusted padding for balance
  textTransform: 'none', // Keeps the text in normal case for a cleaner appearance
  display: 'flex',
  alignItems: 'center', // Aligns icon and text
  gap: '8px', // Adds space between the icon and text
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main, // Uses primary color for consistency
  color: '#fff', // White text
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
  transition: 'background-color 0.3s ease, transform 0.3s ease', // Smoother transitions
  '&:hover': {
    backgroundColor: '#a00000', // Darker hover color
    transform: 'scale(1.02)', // Slight scale on hover for interaction feedback
  },
}));


const AgendaSection = () => {
  const [years, setYears] = useState([]);
  const [activeYear, setActiveYear] = useState('');
  const [agendaData, setAgendaData] = useState([]);
  const [activeDay, setActiveDay] = useState('Day 1');
  const [activeTime, setActiveTime] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
    },
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/agenda', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
          },
        });
        const filteredAgenda = response.data.filter(sponsor => sponsor.status === "1");
        const uniqueYears = Array.from(new Set(filteredAgenda.map(item => item.year)));
        setYears(uniqueYears);
        setAgendaData(filteredAgenda);
        const maxYear = Math.max(...uniqueYears);
        setActiveYear(maxYear.toString());

      } catch (error) {
        console.error('Error fetching agenda data:', error);
      }
    };
    fetchData();
  }, []);

  // Helper function to convert time string to a comparable Date object
const parseTimeString = (timeStr) => {
  // Extract the start time (before the dash)
  const [startTime] = timeStr.split(" - ");
  
  // Create a new Date object to represent today's date
  const date = new Date();

  // Extract hour, minute, and AM/PM from the start time
  const [time, period] = startTime.split(" ");
  const [hours, minutes] = time.split(":").map(Number);

  // Convert to 24-hour format
  let hours24 = period === "PM" && hours !== 12 ? hours + 12 : hours;
  if (period === "AM" && hours === 12) {
    hours24 = 0; // handle midnight case
  }

  // Set the time to the date object
  date.setHours(hours24);
  date.setMinutes(minutes);
  
  return date;
};



  const handleYearChange = (event) => {
    setActiveYear(event.target.value);
    setActiveDay('Day 1');
    setActiveTime('');
  };

  const handleDayChange = (event) => {
    setActiveDay(event.target.value);
    setActiveTime('');
  };

  const handleTimeChange = (event) => {
    setActiveTime(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleWhatsAppShare = () => {
    const pdfUrl = "https://edutech-eg.com/wp-content/uploads/2024/04/Forum-Final-Agenda-VF-2024-4-18-2.pdf";
    if (pdfUrl) {
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(pdfUrl)}`;
      window.open(whatsappUrl, '_blank');
    } else {
      console.error('PDF URL is not available');
    }
  };

  const handleDownloadPDF = () => {
    const pdfUrl = "https://edutech-eg.com/wp-content/uploads/2024/04/Forum-Final-Agenda-VF-2024-4-18-2.pdf";
    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'agenda.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('PDF URL is not available');
    }
  };

  const filteredAgenda = agendaData.filter(
    item =>
      (!activeYear || item.year === activeYear) &&
      (!activeDay || item.day === activeDay) &&
      (!activeTime || item.time === activeTime) &&
      (!searchTerm || item.session.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const sortedAgenda = filteredAgenda.sort((a, b) => {
    const timeA = parseTimeString(a.time);
    const timeB = parseTimeString(b.time);
    return timeA - timeB;
  });
  

  return (
    <div className="agenda-section">
      <div className="section-title-container">
        <Box sx={{ textAlign: 'center', mt: 4 }}>

<StyledTitle variant="h5" gutterBottom>
Agenda</StyledTitle>
</Box>
        <ThemeProvider theme={theme}>
        <Card style={{marginBottom:5}} className="filter-card">
  <CardContent>
    <div className="filters">
      <Box sx={{ minWidth: 150, margin: "10px" }} className="agenda-select">
      <FormControl fullWidth>
      <InputLabel>Year</InputLabel>
        <Select
        label="Year"
        name="Year"
          onChange={handleYearChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Year' }}
          value={activeYear}
        >
          {years.map((year, index) => (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </Box>

      {activeYear && (
        <Box sx={{ minWidth: 150, margin: "10px" }} className="agenda-select">
          <FormControl fullWidth>
      <InputLabel>Day</InputLabel>
          <Select
          label="Day"
          name="Day"
            value={activeDay}
            onChange={handleDayChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Day' }}
          >
            {agendaData
              .filter(item => item.year === activeYear)
              .map(item => item.day)
              .filter((day, index, self) => self.indexOf(day) === index)
              .map((day, index) => (
                <MenuItem key={index} value={day}>
                  {day}
                </MenuItem>
              ))}
          </Select>
          </FormControl>
        </Box>
      )}

      {activeYear && activeDay && (
        <Box sx={{ minWidth: 150, margin: "10px" }} className="agenda-select">
           <FormControl fullWidth>
      <InputLabel id="time-label">Time</InputLabel>
          <Select
          label="Time"
          name="Time"
          id="time-label"
            value={activeTime}
            onChange={handleTimeChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Time' }}
          >

            {agendaData
              .filter(item => item.year === activeYear && item.day === activeDay)
              .map((item, index) => (
                <MenuItem key={index} value={item.time}>
                  {item.time}
                </MenuItem>
              ))}
          </Select>
          </FormControl>
        </Box>
      )}

<Box sx={{ minWidth: 300, margin: "10px" }} className="search-bar">
  <TextField
    id="search-bar"
    label="Search Agenda"
    variant="outlined"
    value={searchTerm}
    onChange={handleSearchChange}
    fullWidth
    sx={{
      '& .MuiOutlinedInput-root': {
        borderRadius: '25px', // Rounded corners
        padding: '10px 15px', // Padding
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
        transition: 'box-shadow 0.3s ease',
        '& fieldset': {
          borderColor: '#dcdcdc', // Light gray border
        },
        '&:hover fieldset': {
          borderColor: '#8B0000', // Dark red border on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: '#8B0000', // Dark red border on focus
          boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)', // Stronger shadow on focus
        },
      },
      '& .MuiInputLabel-root': {
        color: '#555', // Label color
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#8B0000', // Label color on focus
      },
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon sx={{ color: '#8B0000' }} /> {/* Adjust color as needed */}
        </InputAdornment>
      ),
    }}
  />
</Box>



    </div>

    <div className="action-buttons">
      <ThemeProvider theme={theme}>
        <StyledButton variant="contained" color="success" onClick={handleWhatsAppShare}>
          Share Agenda on WhatsApp <FaWhatsapp />
        </StyledButton>
        <StyledButton variant="contained" onClick={handleDownloadPDF}>
          Download Agenda <FaDownload />
        </StyledButton>
      </ThemeProvider>
    </div>
  </CardContent>
</Card>
</ThemeProvider>

      </div>

      <div className="agenda-content">
  {sortedAgenda.map((item, index) => (
    <Card key={index} className="agenda-card">
      <CardContent>
        <Typography variant="h6" className="session-title">
          {item.session}
        </Typography>
        <Typography variant="body2" color="textSecondary" className="session-time">
          {item.time}
        </Typography>

        {item.moderator && (
          <div className="moderator">
            <Typography variant="subtitle2" className="section-heading">
              Moderator
            </Typography>
            <Typography variant="body2">
              {item.moderator}
            </Typography>
          </div>
        )}

        {item.speakers.length > 0 && (
          <div className="speakers-list">
            <Typography variant="subtitle2" className="section-heading">
              Speakers
            </Typography>
            <div className="speakers">
              {item.speakers.map((speaker, speakerIndex) => (
                <Typography key={speakerIndex} variant="body2" className="speaker">
                  {speaker}
                </Typography>
              ))}
            </div>
          </div>
        )}

        {item.description && (
          <div className="description">
            <Typography variant="subtitle2" className="section-heading">
              Description
            </Typography>
            <Typography variant="body2">
              {item.description}
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  ))}
</div>
    </div>
  );
};

export default AgendaSection;
