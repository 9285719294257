import React from 'react';

import Header from './Header';
import Footer from './Footer'
import Stage from './Stage'

import HelpButton from './HelpButton';

const StagePage = () => {
    return (
      <div>
          <Header />
        

        <Stage />
        <HelpButton/>
  
        <hr style={{ borderColor: 'darkred', marginTop: 0, borderWidth: '5px' }} />
  
        <Footer />
  
  
  
      </div>
    );
  };
  
  export default StagePage;
  