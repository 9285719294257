import React, { useState, useEffect } from 'react';
import FAQItem from './FAQItem';
import axios from 'axios';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './FAQ.css';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';




const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000", // Dark Red
    },
  },
});

const FAQ = () => {
  const [faqData, setFaqData] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchFAQData = async () => {
      try {
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/FAQ', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        });
        setFaqData(response.data);
      } catch (error) {
        console.error('Error fetching FAQ data:', error);
      }
    };

    fetchFAQData();
  }, []);

  const handleShowMoreToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="faq-container">
        <Box sx={{ textAlign: 'center', mt: 4 }}>
        <StyledTitle variant="h5" gutterBottom>
        Frequently Asked Questions</StyledTitle>
</Box>
      {faqData.slice(0, isExpanded ? faqData.length : visibleCount).map((item, index) => (
        <FAQItem
          key={index}
          question={item.question}
          answer={item.answer}
        />
      ))}
      <ThemeProvider theme={theme}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleShowMoreToggle}
          sx={{ mb: 2 }}
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </Button>
      </ThemeProvider>
    </div>
  );
};

export default FAQ;
