import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Link } from 'react-router-dom';

const UnderConstruction = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f9f9f9', // Light background color
        color: '#333', // Dark text color
        padding: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 3,
        }}
      >
        <ConstructionIcon sx={{ fontSize: 80, mb: 2, color: '#f5a623' }} />
        <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
          Page Under Maintenance
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1.2rem', maxWidth: '600px', mb: 3 }}>
          We are currently working on this page. Please check back later for updates. Your patience is appreciated!
        </Typography>
      </Box>
      <Button 
        component={Link} 
        to="/" 
        variant="contained" 
        sx={{ 
          backgroundColor: '#f5a623', 
          color: '#fff', 
          '&:hover': {
            backgroundColor: '#e59400',
          },
        }}
      >
        Return to Home
      </Button>
      <Box sx={{ mt: 4 }}>
        <Typography variant="caption" sx={{ color: '#666' }}>
          If you need assistance, contact us at <a href="mailto:info@edugate-eg.com" style={{ color: '#f5a623', textDecoration: 'underline' }}>info@edugate-eg.com</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default UnderConstruction;
