import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import './CountdownComponent.css';
import { styled } from '@mui/system';
import { Card } from 'react-bootstrap';

const StyledTitle2 = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: 'clamp(0.5rem, 4vw, 1rem)', // Responsive font size
  color: 'transparent', // Text color set to transparent so gradient shows
  background: 'linear-gradient(135deg, #FFFFFF 0%, #E0E0E0 100%)', // White gradient background for the text
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  marginBottom:"2px",
  
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    background: 'linear-gradient(135deg, #FFFFFF 0%, #E0E0E0 100%)', // Gradient white underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },

  '&:hover::after': {
    transform: 'scaleX(1)', // Underline expands on hover
    transformOrigin: 'bottom left',
  },
}));

const SubscribeToNewsletter = () => {
  const [showSubscribe, setShowSubscribe] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [feedback, setFeedback] = useState({
    message: '',
    type: '', // 'success' or 'error'
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000"
      },
    }
  });

  const [formData, setFormData] = useState({
    email:"",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
  

    setFormData({ ...formData, [name]: value });  };

  useEffect(() => {
    // Disable scrolling when the modal is open
    if (showSubscribe) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Cleanup function to re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showSubscribe]);

  const apiKey = process.env.REACT_APP_API_KEY;


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
  
    if (!formData.email) {
      setFeedback({
        message: "Please enter your email",
        type: 'error',
      });
      setIsSubmitting(false);
      return;
    }
  
    try {
      // Send the form data to the backend API
      const response = await fetch('https://edutech-backend-471f7cb69116.herokuapp.com/api/newsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey, // Include the API key in the request headers
        },
        body: JSON.stringify(formData),
      });
  
      if (response.status === 201) {
        setFeedback({
          message: "Subscribed successfully",
          type: 'success',
        });
        setTimeout(() => {
          setShowSubscribe(false);
        }, 3000);
      } else {
        // Handle errors from the backend
        setFeedback({
          message: "Error submitting the form",
          type: 'error',
        });
      }
    } catch (error) {
      // Handle network errors
      setFeedback({
        message: "Error submitting the form",
        type: 'error',
      });
    }
  };
  

  const handleClose = () => {
    setShowSubscribe(false);
  };

  return (
    <>
      {showSubscribe && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000', // Ensure it appears above other content
            

          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative', // Required for absolute positioning of button
              backgroundImage: `url("https://edugate-eg.com/wp-content/uploads/2024/03/171166430740415368-1.webp")`, // Replace with your image URL
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight:"250px",

            }}
          >
                    <Container  component="main" sx={{ width: '90%' }}>

            <CloseIcon
              style={{ cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' ,color:"white", marginBottom:"10px"}}
              onClick={handleClose}
            />
            
         
              <ThemeProvider theme={theme}>
              <form onSubmit={handleSubmit} >
                

              <Box className="countdown-overlay">
              <StyledTitle2 >
              Subscribe to our newsletter to get the latest news!
            </StyledTitle2>

            <Card
  sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Vertically centers the content
    alignItems: 'center', // Horizontally centers the content
    padding: '20px', // Adds padding around the content
    width: '100%', // Ensures the card is responsive
  }}
>
  <TextField
    label="Enter your email"
    variant="outlined"
    required
    type="email"
    name="email"
    value={formData.email}
    onChange={handleChange}
    sx={{
      backgroundColor: 'white',
      marginBottom: '10px',
      width: '80%', // Adjusts the width of the input field
      display: 'flex',

    }}
  />
</Card>


<Button
  variant="contained"
  color="primary"
  className='button'
  disabled={isSubmitting}
  type="submit"
>
  Subscribe
</Button>
                </Box>
                

                </form>

                {feedback.message && (
                  <Typography
                    variant="body1"
                    style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                  >
                    {feedback.message}
                  </Typography>
                )}
              </ThemeProvider>

            </Container>

          </div>
        </div>
      )}
    </>
  );
};

export default SubscribeToNewsletter;
