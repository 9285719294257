import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './BlogSection.css'; 
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [blogsPerPage] = useState(3);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/blogs', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
          },
        });
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchData();
  }, []);

  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return title.slice(0, maxLength) + '...';
    }
    return title;
  };

  const indexOfLastBlog = page * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ff6347",
      },
    },
    typography: {
      button: {
        fontWeight: 'bold',
      },
    },
  });

  return (
    <div className="blog-section">

<Box sx={{ textAlign: 'center', mt: 4 }}>
        <StyledTitle variant="h5" gutterBottom>
        Latest News
</StyledTitle>
</Box>
      <div className="blog-cards">
        {currentBlogs.map((blog) => (
          <Card key={blog._id} className="blog-card">
            <Card.Img variant="top" src={blog.src} alt={blog.title} className="blog-image" />
            <Card.Body>
              <Card.Title className="blog-title">{truncateTitle(blog.title, 50)}</Card.Title> {/* Adjust maxLength as needed */}
              <Card.Text className="blog-excerpt">{blog.excerpt}</Card.Text>
              <Link to={`/blogs/${blog._id}`} style={{ textDecoration: 'none' }}>
                <ThemeProvider theme={theme}>
                  <Button variant="contained" color="primary" aria-label={`Read more about ${blog.title}`}>
                    Read More
                  </Button>
                </ThemeProvider>
              </Link>
            </Card.Body>
          </Card>
        ))}
      </div>
      {blogs.length > blogsPerPage && (
        <Stack spacing={2} justifyContent="center" alignItems="center" className="pagination-container">
          <ThemeProvider theme={theme}>
            <Pagination
              count={Math.ceil(blogs.length / blogsPerPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </ThemeProvider>
        </Stack>
      )}
    </div>
  );
};

export default BlogSection;
