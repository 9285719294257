import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import './ClientCarouselSection.css';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
function ClientCarouselSection() {
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    // Fetch official sponsor logos from the API
    const fetchSponsors = async () => {
      try {
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getOfficialSponsors');
        const filteredSponsors = response.data.filter(sponsor => sponsor.status === "0");
        setSponsors(filteredSponsors);
      } catch (error) {
        console.error("Error fetching official sponsors:", error);
      }
    };

    fetchSponsors();
  }, []);

  const settings = {
    infinite: true,
    speed: 700,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  };

  return (
    <section id="clients" className="custom-carousel-section">
        <Box sx={{ textAlign: 'center', mt: 4 }}>
        <StyledTitle variant="h5" gutterBottom>
        Our Official Sponsors</StyledTitle>
</Box>
      <div className="custom-carousel-wrapper">
        <Slider {...settings}>
          {sponsors.map((sponsor, index) => (
            <div key={index} className="custom-carousel-item-content">
              <img
                className="custom-client-logo"
                src={sponsor.image} // Use the "image" attribute from the API response
                alt={`Sponsor Logo ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default ClientCarouselSection;
