import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const NotFound = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <Container 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh', 
        textAlign: 'center',
        bgcolor: '#ffffff', 
        padding: 4,
        borderRadius: 4,
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 4,
        }}
      >
        <Box
          sx={{
            borderRadius: '50%',
            backgroundColor: '#f5f5f5',
            padding: 2,
            mb: 2,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 80, color: '#f44336' }} />
        </Box>
        <Typography 
          variant="h1" 
          component="h1" 
          gutterBottom 
          sx={{ 
            fontSize: { xs: '3rem', md: '5rem' }, 
            fontWeight: 'bold', 
            color: '#333',
            mb: 2,
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          404
        </Typography>
        <Typography 
          variant="h5" 
          component="h2" 
          gutterBottom 
          sx={{ 
            mb: 2, 
            color: '#555',
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          Page Not Found
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 4, 
            color: '#777', 
            maxWidth: '600px',
            lineHeight: 1.5,
            mx: 'auto',
          }}
        >
          It looks like the page you were trying to reach is either missing or has been moved. Please use the button below to return to the homepage or navigate through our site.
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleHomeClick} 
          sx={{
            px: 4, 
            py: 2, 
            fontSize: '1rem', 
            borderRadius: 3,
            textTransform: 'none',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              boxShadow: '0 6px 8px rgba(0, 0, 0, 0.3)',
            }
          }}
        >
          Back to Home
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
