import React, { useState, useEffect } from 'react';
import CarouselComponent from './CarouselComponent';
import CountdownComponent from './CountdownComponent';
import Header from './Header';
import ServicesComponent from './ServicesComponent';
import ClientCarouselSection from './ClientCarouselSection';
import ContactSectionComponent from './ContactSectionComponent';
import Footer from './Footer';
import FAQ from './FAQ';
import FigureCard from './FigureCard';
import HelpButton from './HelpButton';
import BlogSection from './BlogSection';
import PartnerSection from './PartnerSection';
import SubscribeToNewsletter from './SubscribeToNewsletter';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import EventAnnouncement from './EventAnnouncement';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const Home = () => {
  const [figuresData, setFiguresData] = useState({
    students: 0,
    events: 0,
    exhibitors: 0,
    international_organizations: 0,
    ministries: 0,
  });
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
          },
        });
        setFiguresData(response.data[0]);
      } catch (error) {
        console.error('Error fetching figures data:', error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <main>
      <Header />
      <CarouselComponent />

      <SubscribeToNewsletter />

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <ServicesComponent />

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <CountdownComponent />

      <section style={{ padding: '40px 0', backgroundColor: '#f9f9f9' }}>
       
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <StyledTitle variant="h5" gutterBottom>
        The Numbers That Matter</StyledTitle>
</Box>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '20px', justifyContent: 'center' }}>
          <FigureCard label="Visitors" number={figuresData.students} />
          <FigureCard label="Events" number={figuresData.events} />
          <FigureCard label="Exhibitors" number={figuresData.exhibitors} />
          <FigureCard label="Countries" number={figuresData.international_organizations} />
          <FigureCard label="Ministries" number={figuresData.ministries} />
        </div>
      </section>

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <ClientCarouselSection />
      <PartnerSection />

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <BlogSection />

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <ContactSectionComponent />
      
      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <FAQ />
      <HelpButton />

      <hr style={{ borderColor: '#d32f2f', margin: '20px 0', borderWidth: '2px', borderStyle: 'solid' }} />

      <Footer />
    </main>
  );
};

export default Home;
//////test