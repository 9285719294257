import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactSectionComponent.css';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube, FaEnvelope, FaPhone } from "react-icons/fa";
import axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import ContactForm from './ContactForm';
import Typography from '@mui/material/Typography';

const StyledTitle2 = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent', // Text color set to transparent so gradient shows
  background: 'linear-gradient(135deg, #FFFFFF 0%, #E0E0E0 100%)', // White gradient background for the text
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    background: 'linear-gradient(135deg, #FFFFFF 0%, #E0E0E0 100%)', // Gradient white underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },

  '&:hover::after': {
    transform: 'scaleX(1)', // Underline expands on hover
    transformOrigin: 'bottom left',
  },
}));



function ContactSectionComponent() {
  const [contact, setContact] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/contact', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
          },
        });
        setContact(response.data[0]);
      } catch (error) {
        console.error('Error fetching contact data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <section id='contact-section' className="contact-section">
      <Container>
       
      <Box sx={{ textAlign: 'center', mt: 4 }}>
            <StyledTitle2 variant="h5" gutterBottom>
            Ready to Join Us
</StyledTitle2>
</Box>
        <Row>
          <Col md={6} sm={12} className="mb-4 order-2 order-md-1">
            <Card className="contact-card">
              <Card.Body>
                <div className="contact-details">
                  <h3>Contact Details</h3>
                  <p>
                    <FaEnvelope className="contact-icon" />
                    <a href={`mailto:${contact.email}`} className="contact-link">
                      {contact.email}
                    </a>
                  </p>
                  <p>
                    <LocationOnIcon className="contact-icon" />
                    <a href={contact.link} target="_blank" rel="noopener noreferrer" className="contact-link">
                      {contact.address}
                    </a>
                  </p>
                  <p>
                    <FaPhone className="contact-icon" />
                    <a href={`tel:${contact.phone}`} className="contact-link">
                      {contact.phone}
                    </a>
                  </p>
                  <h3>Stay Connected!</h3>
                  <h6 className="subtext">Follow us for the latest news about EDU TECH.</h6>
                </div>
                <div className="social-media">
                  <hr className="divider" />
                  <div className="icon-container">
                    <a href={contact.facebook} target="_blank" rel="noopener noreferrer"><FaFacebook className="facebook-icon" /></a>
                    <a href={contact.youtube} target="_blank" rel="noopener noreferrer"><FaYoutube className="youtube-icon" /></a>
                    <a href={contact.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedin className="linkedin-icon" /></a>
                    <a href={contact.instagram} target="_blank" rel="noopener noreferrer"><FaInstagram className="instagram-icon" /></a>
                  </div>
                </div>
                <ContactForm />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mb-4 order-1 order-md-2">
            <Card className="map-card">
              <Card.Body>
                <div className="map-container">
                  <iframe
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3453.9047066681255!2d31.230556075653567!3d30.03959161880045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAyJzIyLjUiTiAzMcKwMTMnNTkuMyJF!5e0!3m2!1sen!2seg!4v1710014290514!5m2!1sen!2seg"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ContactSectionComponent;
