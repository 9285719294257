import React, { useState, useEffect } from 'react';
import { FaLinkedin } from 'react-icons/fa';
import './SpeakersSection.css';
import axios from 'axios';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));

const SpeakersSection = () => {
  const [selectedYear, setSelectedYear] = useState(''); // Initialize selectedYear state
  const [speakersData, setSpeakersData] = useState([]); // State to store fetched speakers data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY; // Load API key from environment variable
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/speakers', {
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey, // Include the API key in the request headers
          },
        });
        setSpeakersData(response.data); // Set the speakers data in state

        // Find the maximum year and set it as the selected year
        const years = Array.from(new Set(response.data.map(speaker => speaker.year)));
        const maxYear = Math.max(...years);
        setSelectedYear(maxYear.toString());
      } catch (error) {
        console.error('Error fetching speakers data:', error);
      }
    };
  
    fetchData();
  }, []);
  

  const years = Array.from(new Set(speakersData.map((speaker) => speaker.year)));

  return (
    <section className="speakers-section">
      <div className="container">
        <Box>
        <StyledTitle variant="h5" gutterBottom>
        Meet Our Inspiring Speakers</StyledTitle>
</Box>
        <div style={{margin:"10px"}} className="year-tabs">
          {years.map((year, index) => (
            <div
              key={index}
              className={`year-tab ${
                selectedYear === year ? 'active' : ''
              }`}
              onClick={() => setSelectedYear(year)}
            >
              {year}
            </div>
          ))}
        </div>
        <div className="speakers-list">
          {speakersData
            .filter((speaker) => speaker.year === selectedYear)
            .map((speaker, index) => (
              <div key={index} className="speaker-card">
                <div className="speaker-image">
                  <img
                    src={speaker.image}
                    alt={`Speaker ${index}`}
                    className="speaker-avatar"
                  />
                </div>
                <h3 className="speaker-name">{speaker.name}</h3>
                <p className="speaker-title">{speaker.title}</p>
                <div className="speaker-social">
                  {speaker.link && (
                    <a
                      href={speaker.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </a>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default SpeakersSection;
