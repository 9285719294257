import React, { useState, useEffect } from 'react';
import { Container, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import './PartnerSection.css';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));

function PartnerSection() {
  const [isCarouselPaused, setIsCarouselPaused] = useState(false);
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    // Fetch sponsor images from the API
    const fetchSponsors = async () => {
      try {
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getSponsors');
        const filteredSponsors = response.data.filter(sponsor => sponsor.status === "0");
        setSponsors(filteredSponsors);
      } catch (error) {
        console.error("Error fetching sponsors:", error);
      }
    };

    fetchSponsors();
  }, []);

  const handleCarouselHover = (hovered) => {
    setIsCarouselPaused(hovered);
  };

  return (
    <section id="clients" className="client-carousel-section">
      <Container>
        <div
          className="carousel-section"
          onMouseEnter={() => handleCarouselHover(true)}
          onMouseLeave={() => handleCarouselHover(false)}
        >
        <Box sx={{ textAlign: 'center', mt: 4 }}>
        <StyledTitle variant="h5" gutterBottom>
        Our Success Partners</StyledTitle>
</Box>
          <Carousel
            interval={null}
            fade={true}
            indicators={false}
            controls={false}
            pause={isCarouselPaused}
          >
            <Carousel.Item>
              <div className="logo-group">
                {sponsors.map((sponsor, index) => (
                  <img
                    key={index}
                    className="client-logo2"
                    src={sponsor.image}  // Use the "image" attribute from the API response
                    alt={`Sponsor Logo ${index + 1}`}
                  />
                ))}
              </div>
            </Carousel.Item>
            {/* Add more Carousel.Items for additional groups if necessary */}
          </Carousel>
        </div>
      </Container>
    </section>
  );
}

export default PartnerSection;
